:root {
  --oultine-offset: var(--gap-2);
  --background: #f8f9fa;
  --color-gray-100: #f8f9fa;
  --color-gray-200: #e9ecef;
  --color-gray-300: #dee2e6;
  --color-gray-400: #ced4da;
  --color-gray-500: #adb5bd;
  --color-gray-600: #6c757d;
  --color-gray-700: #495057;
  --color-gray-800: #343a40;
  --color-gray-900: #212529;
  --color-white: #fff;
  --color-black: #000;
  --color-red: #dc3545;
  --color-orange: #fd7e14;
  --color-yellow: #ffc107;
  --color-green: #28a745;
  --color-teal: #20c997;
  --color-cyan: #17a2b8;
  --color-blue: #007bff;
  --color-indigo: #6610f2;
  --color-purple: #6f42c1;
  --color-pink: #e83e8c;
  --gap-base: 1rem;
  --gap-1: calc(var(--gap-base) * .25);
  --gap-2: calc(var(--gap-base) * .5);
  --gap-3: calc(var(--gap-base) * .75);
  --gap-4: calc(var(--gap-base) * 1);
  --gap-5: calc(var(--gap-base) * 1.25);
  --gap-6: calc(var(--gap-base) * 1.5);
  --gap-7: calc(var(--gap-base) * 1.75);
  --gap-8: calc(var(--gap-base) * 2);
  --gap-9: calc(var(--gap-base) * 2.25);
  --gap-10: calc(var(--gap-base) * 2.5);
  --gap-11: calc(var(--gap-base) * 2.75);
  --gap-12: calc(var(--gap-base) * 3);
  --gap-13: calc(var(--gap-base) * 3.25);
  --gap-14: calc(var(--gap-base) * 3.5);
  --gap-15: calc(var(--gap-base) * 3.75);
  --gap-16: calc(var(--gap-base) * 4);
  --gap-17: calc(var(--gap-base) * 4.25);
  --gap-18: calc(var(--gap-base) * 4.5);
  --gap-19: calc(var(--gap-base) * 4.75);
  --gap-20: calc(var(--gap-base) * 5);
  --font-size-base: 1.25rem;
  --font-size-8: calc(var(--font-size-base) * .875);
  --font-size-7: calc(var(--font-size-base) * 1);
  --font-size-6: calc(var(--font-size-base) * 1.125);
  --font-size-5: calc(var(--font-size-base) * 1.25);
  --font-size-4: calc(var(--font-size-base) * 1.5);
  --font-size-3: calc(var(--font-size-base) * 1.75);
  --font-size-2: calc(var(--font-size-base) * 2);
  --font-size-1: calc(var(--font-size-base) * 3);
  --radius-base: .25rem;
  --radius-1: calc(var(--radius-base) * .5);
  --radius-2: calc(var(--radius-base) * .75);
  --radius-3: calc(var(--radius-base) * 1);
  --radius-4: calc(var(--radius-base) * 1.25);
  --radius-5: calc(var(--radius-base) * 1.5);
  --radius-6: calc(var(--radius-base) * 1.75);
  --radius-7: calc(var(--radius-base) * 2);
  --radius-8: calc(var(--radius-base) * 3);
  --font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.sortable-ghost {
  opacity: .4;
  background-color: #f0f0f0;
}

* {
  box-sizing: border-box;
  border: 0 solid #0000;
  margin: 0;
  padding: 0;
}

body, button, form {
  font-size: var(--font-size-base);
  font-family: var(--font-family);
}

code, pre {
  font-family: ui-monospace, monospace;
}

hr {
  margin-block: var(--gap-base, 1rem);
  border: 0;
  border-top: var(--px, 1.5px) solid var(--color-gray-300, lightgrey);
  width: 100%;
  display: block;
}

button {
  cursor: pointer;
  padding-inline: calc(var(--gap-base, 1rem) / 2);
  padding-block: calc(var(--gap-base, 1rem) / 3);
  border-radius: var(--radius-2, .25rem);

  &.full {
    width: 100%;
  }

  &.align-left {
    text-align: start;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: end;
  }
}

:where(.done-user-block, done-block-text, done-block-quote, done-block-image, done-block-link, done-block-heading, done-block-code) {
  padding-block: calc(var(--gap-base, 1rem) * 1);
}

blockquote {
  margin-block: var(--gap-base, 1rem);
  border-left: var(--px, 1.5px) solid var(--color-gray-300, lightgrey);
  font-size: var(--font-size-5);
  padding-inline-start: var(--gap-base, 1rem);
}

h1 {
  font-size: clamp(calc(var(--font-size-1)  - 1rem), calc(var(--font-size-1)  + 1vw), calc(var(--font-size-1)  + 8rem));
  line-height: 1;
}

h2 {
  font-size: clamp(calc(var(--font-size-2)  - 7vw), calc(var(--font-size-2)  + 1vw), calc(var(--font-size-2)  + 7rem));
  line-height: 1;
}

h3 {
  font-size: clamp(calc(var(--font-size-3)  - 6vw), calc(var(--font-size-3)  + 1vw), calc(var(--font-size-3)  + 6rem));
  line-height: 1;
}

h4 {
  font-size: clamp(calc(var(--font-size-4)  - 5vw), calc(var(--font-size-4)  + 1vw), calc(var(--font-size-4)  + 5rem));
  line-height: 1;
}

h5 {
  font-size: clamp(calc(var(--font-size-5)  - 4vw), calc(var(--font-size-5)  + 1vw), calc(var(--font-size-5)  + 4rem));
  line-height: 1;
}

h6 {
  font-size: clamp(calc(var(--font-size-6)  - 3vw), calc(var(--font-size-6)  + 1vw), calc(var(--font-size-6)  + 3rem));
  line-height: 1;
}

p {
  font-size: clamp(calc(var(--font-size-7)  - 2vw), calc(var(--font-size-7)  + 1vw), calc(var(--font-size-7)  + 2rem));
  line-height: 1.25;
}

small {
  font-size: clamp(calc(var(--font-size-8)  - 1vw), calc(var(--font-size-8)  + 1vw), calc(var(--font-size-8)  + 1rem));
  line-height: 1.33;
}

:where(.done-block) {
  display: block;
}

.done-app {
  width: 100%;
  overflow: hidden scroll;

  @media screen and (width >= 768px) {
    & {
      grid-template-columns: 3fr minmax(320px, 60vw) 3fr;
      height: 100lvh;
      display: grid;
    }
  }
}

done-user-container {
  padding-inline: var(--gap-base, 1rem);
}

table {
  width: 100%;
}

.done-block {
  display: block;
}

done-view {
  padding-bottom: var(--gap-8, 6rem);

  @media screen and (width >= 1040px) {
    & {
      padding-block: calc(var(--gap-base, 1rem) * 4);
      padding-inline: calc(var(--gap-base, 1rem) * 4);
    }
  }
}

[contenteditable]:focus {
  outline: none;
}

.done-user-block:hover {
  border-radius: var(--radius-2);
  outline: var(--px, 1.5px) solid var(--color-gray-300, #1d96ff);
  outline-offset: var(--oultine-offset);
}

.done-user-block:focus {
  outline: var(--px, 1.5px) solid var(--color-gray-300, #ededed);
}

.done-view-header {
  padding-block: var(--gap-base, 1rem);
  padding-inline: var(--gap-base, 1rem);
  position: relative;

  & table, & .linked-children p {
    margin-top: var(--gap-base, 1rem);
    font-size: .8em;

    & button {
      margin-top: var(--gap-2);
      font-size: .8em;
    }
  }

  & .done-image, & figure, & img {
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: clamp(320px, 25vh + 15vw, 640px);
    line-height: 0;
  }

  & .done-title {
    width: calc(100% - var(--gap-base, 1rem));
    font-size: var(--font-size-1);
    margin-block: var(--gap-base, 1rem);
    font-weight: 700;
  }
}

done-sidebar {
  background-color: var(--gray-100, #0000000d);
  display: none;
  position: relative;

  &:after {
    content: "";
    background-color: var(--color-gray-300, lightgrey);
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  & done-sidebar-header {
    font-weight: 700;
  }

  & .done-sidebar {
    top: var(--gap-base, 1rem);
    position: sticky;
  }

  & ul {
    margin: 0;
    margin-block: var(--gap-base, 1rem);
    padding-inline: 0;
    list-style: none;
  }

  & button {
    margin-bottom: calc(var(--gap-base, 1rem) / 2);
    border: var(--px, 1.5px) solid var(--color-gray-300, lightgrey);
    font-size: .8em;
  }

  @media screen and (width >= 768px) {
    & {
      padding-block: var(--gap-base, 1rem);
      padding-inline: var(--gap-base, 1rem);
      border-right: 1px solid var(--color-gray-300, lightgrey);
      display: block;
    }
  }
}

.done-add-block {
  padding: var(--gap-base, 1rem);
  margin-block: var(--gap-6, 2rem);

  &:is(:hover, :focus) {
    background-color: var(--color-gray-200, #e9ecef);
  }
}

done-block-image {
  & img {
    margin-block: var(--gap-base, 1rem);
    width: 100%;
    height: auto;
    display: block;
  }
}

done-block-code {
  & code {
    padding-block: var(--gap-base, 1rem);
    padding-inline: var(--gap-base, 1rem);
    background-color: var(--color-gray-100, #f8f9fa);
    border-radius: var(--radius-2, .25rem);
    font-size: var(--font-size-7);
    display: block;
    overflow-x: auto;
  }
}

done-sidebar {
  & [data-done-is-child-of] {
    max-width: calc(100% - var(--gap-base, 1rem) * 1);
    margin-inline-start: var(--gap-base, 1rem);
  }
}

.done-link {
  padding-inline: var(--gap-base, 1rem);
  padding-block: var(--gap-base, 1rem);
  border-radius: var(--radius-2, .25rem);
  background-color: var(--color-gray-100, #f8f9fa);
  font-size: var(--font-size-7);
  color: var(--color-gray-800, #343a40);
  cursor: pointer;
  text-decoration: none;
  transition: background-color .2s ease-in-out;
  display: block;

  &:hover {
    background-color: var(--color-gray-200, #e9ecef);
  }
}
/*# sourceMappingURL=index.58929fac.css.map */
